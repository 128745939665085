<template>
	<div>
		<van-nav-bar title="忘记密码" left-arrow fixed placeholder :border="false" @click-left="onClickLeft" />
		<van-form ref="form1" @submit="submit">
			<van-cell-group inset>
				<van-field v-model="form.phone" name="手机号" label="手机号" placeholder="请输入手机号"
					:rules="[{ required: true}]" />
				<div class="getphone">
					<van-field v-model="form.code" label="验证码" placeholder="请输入短信验证码" :rules="[{ required: true}]">
						<template #button>
							<van-button size="small" type="primary" @click="getcode1">{{msg}}</van-button>
						</template>
					</van-field>
				</div>

				<van-field v-model="form.password" type="password" name="新密码" label="新密码" placeholder="请输入新密码"
					:rules="[{ required: true}]" />
				<van-field v-model="form.newPassword" type="password" name="确认密码" label="确认密码" placeholder="请输入确认密码"
					:rules="[{ required: true}]" />
			</van-cell-group>
			<div class="submit">
				<van-button round block type="primary" native-type="submit">
					确认修改
				</van-button>
			</div>
		</van-form>
	</div>
</template>

<script>
	import {
		Toast
	} from 'vant';
	import {
		getcode,
		updatePassWord
	} from "@/api/login"
	export default {
		data() {
			return {
				form: {
					phone: null,
					password: null,
					newPassword: null,
					code: null,
					
				},
				msg: "获取验证码",
				checked: false
			}
		},
		created() {
			this.$store.commit('setSession', this.$route.query.session ||
				"$2a$10$CmPBsI.8LpzeWYSsE4Y5COn.v1y0omqNCmJvIGZFi381ujSm4vZRu")
		},
		methods: {
			onClickLeft() {

				let u = navigator.userAgent;
				let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
				let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
				if (isIOS) {
					window.webkit.messageHandlers.close.postMessage("up");
				}
				if (isAndroid) {
					window.android.close();
				}
				// console.log('返回');
				// this.$router.go(-1)

			},
			onClickRight() {

			},
			getcode1() {
				if(this.msg == "获取验证码"){
				if (this.verify(this.form.phone)) {
					getcode({
						phone: this.form.phone,
						type: 5
					}).then(res => {
						var num = 60
						const that = this
						console.log(res)
						var interval = setInterval(function() {
							num -= 1
							that.msg = num + "秒后重新获取"
							if (num <= 0) {

								clearInterval(interval)
								that.msg = "获取验证码"
							}

						}, 1000)
					})
				} else {
					Toast.fail('请输入正确的手机号');
				}
				}
			},
			verify(val) {
				return /^1\d{10}$/.test(val)
			},
			submit() {
				this.$refs.form1.validate().then(() => {
					// 验证通过
					var form1 = JSON.stringify(this.form)
					updatePassWord(form1).then(res => {
						if(res.data.code == 0){
							Toast("修改成功");
							// this.onClickLeft()
							this.$router.go(-1)
						}else{
							Toast(res.data.message);
						}
					})
				})

			},
		},

	}
</script>

<style lang="less" scoped>
	.getphone .van-button--primary {
		background: #F52556;
		border-radius: 22px;
		border: none;
		height: 25px;
	}

	.submit .van-button--primary {
		width: 94%;
		margin-left: 3%;
		height: 43px;
		background: #F52556;
		opacity: 1;
		border-radius: 22px;
		border: none;
		margin-top: 50px;
		position: absolute;
		bottom: 20px;
	}

	.van-cell {
		height: 48px;
	}
</style>
